import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./gaps-in-care/gaps-in-care.module').then(m => m.GapsInCareModule)
  },
  {
    path: 'dev-sample',
    loadChildren: () => import('./dev-sample/dev-sample.module').then(m => m.DevSampleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
